import {
    CATEGORIZED_COOKIES,
    COOKIE_DEFAULT_CATEGORY
} from 'tradera/constants/cookies';
import { GdprSettings } from 'tradera/utils/gdpr-settings';
import { logger } from 'packages/logger';
import { isServer } from 'tradera/utils/nextjs';
import { getCookieFromBrowser, setCookieToBrowser } from './cookie-helpers';

export class CookieUtil {
    getCookie: (key: string) => string | null | undefined;
    setCookie: (s: string) => void;
    gdpr: GdprSettings;
    constructor(
        getCookie: (key: string) => string | null | undefined,
        setCookie: (s: string) => void
    ) {
        if (!getCookie || !setCookie) {
            throw new Error('You must provide getCookie and setCookie');
        }
        this.getCookie = getCookie;
        this.setCookie = setCookie;
        this.gdpr = new GdprSettings(getCookie, setCookie);
    }

    segment(name: string, value?: string) {
        return value ? '; ' + name + '=' + value : '';
    }

    convertToExpiresStr(expires: number | string | Date) {
        let expiresStr = '';

        if (typeof expires === 'number') {
            expiresStr =
                expires === Infinity
                    ? '; expires=Fri, 31 Dec 9999 23:59:59 GMT'
                    : '; max-age=' + expires * 24 * 60 * 60;
        } else if (typeof expires === 'string') {
            expiresStr = '; expires=' + expires;
        } else if (expires instanceof Date) {
            expiresStr = '; expires=' + expires.toUTCString();
        }

        return expiresStr;
    }

    createCookie(
        cookieKey: string,
        cookieValue: string | number | boolean,
        /**
         * Expiration in number of days
         */
        expires?: number | string | Date,
        path?: string,
        domain?: string,
        secure?: boolean
    ) {
        let expiresStr = '';

        if (
            !cookieKey ||
            /^(?:expires|max-age|path|domain|secure)$/i.test(cookieKey)
        ) {
            return false;
        }

        // check gdpr for cookie category
        let category = CATEGORIZED_COOKIES[cookieKey];
        if (typeof category === 'undefined') {
            logger(`No category set for cookie ${cookieKey}`);
            category = COOKIE_DEFAULT_CATEGORY;
        }
        if (!this.gdpr.isCategoryEnabled(category)) {
            // console.info(
            //     `Cookie category not enabled: ${cookieKey}, category: ${category}`
            // );
            return false;
        }

        // if expired set prepare date string
        if (expires) {
            expiresStr = this.convertToExpiresStr(expires);
        }

        if (isServer) {
            throw new Error('Setting cookie is not supported on the server');
        }

        this.setCookie(
            encodeURIComponent(cookieKey) +
                '=' +
                encodeURIComponent(cookieValue) +
                expiresStr +
                this.segment('domain', domain) +
                this.segment('path', path || '/') +
                (location.protocol == 'https:' || secure ? '; secure' : '')
        );

        return true;
    }

    readCookie(key: string) {
        return this.getCookie(key);
    }

    hasCookie(key: string) {
        return typeof this.readCookie(key) === 'string';
    }

    eraseCookie(key: string, path?: string, domain?: string) {
        if (!key || !this.hasCookie(key)) {
            return false;
        }

        if (isServer) {
            throw new Error('Setting cookie is not supported on the server');
        }

        this.setCookie(
            encodeURIComponent(key) +
                '=; expires=Thu, 01 Jan 1970 00:00:01 GMT' +
                this.segment('domain', domain) +
                this.segment('path', path || '/')
        );

        return true;
    }
}

export default new CookieUtil(getCookieFromBrowser, setCookieToBrowser);
