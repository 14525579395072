export const parseAppVersion = (version) => {
    const [major = 0, minor = 0, patch = 0] = String(version)
        .split('.')
        .map(Number)
        .filter((number) => !Number.isNaN(number));
    return { major, minor, patch };
};

export const isSupportedMinimumVersion = (
    appVersionString,
    minimumVersionString
) => {
    const appVersion = parseAppVersion(appVersionString);
    const minimumVersion = parseAppVersion(minimumVersionString);
    if (appVersion.major > minimumVersion.major) {
        return true;
    }
    if (appVersion.major === minimumVersion.major) {
        if (appVersion.minor > minimumVersion.minor) {
            return true;
        }
        if (appVersion.minor === minimumVersion.minor) {
            if (appVersion.patch >= minimumVersion.patch) {
                return true;
            }
        }
    }

    return false;
};
