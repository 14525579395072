import axios from 'axios';
import { selectPreferredLanguageCode } from 'tradera/state/language/selectors';
import { toLocalizedUrl } from 'tradera/utils/url';
import {
    setEnvironmentHash,
    setIsSpaNavigationEnabled,
    setGATrackingData,
    setNativeAppInfo,
    setVariables,
    setVersion,
    setFeatureSwitches,
    setSplitTestGroups,
    setEnvironment
} from './reducer';
import { isNextJs } from 'tradera/utils/nextjs';
import { selectMemberId } from 'tradera/state/member/selectors';
import {
    createNativeAppCookieValue,
    extractNativeAppInfoFromCookie,
    extractNativeAppInfoFromInitData
} from './native-app-info-helper';
import cookie from 'tradera/utils/cookie';
import { NATIVE_APP_ENVIRONMENT } from 'tradera/constants/cookies';
import { GtmService as gtm } from 'tradera/services/google-tagmanager/google-tagmanager-service';
import type { AppDispatch, GetState } from 'tradera/state/configure-store';
import {
    isAndroid,
    isMobile,
    isMobileSafari,
    isIOS,
    browserName
} from 'react-device-detect';

const getVersionUrl = (getState: GetState) => {
    if (isNextJs) {
        const queryParameters = new URLSearchParams({ next: '1' });
        const memberId = selectMemberId(getState());
        if (memberId) {
            queryParameters.set('memberId', `${memberId}`);
        }
        return `/api/version?${queryParameters}`;
    }

    const preferredLanguageCode = selectPreferredLanguageCode(getState());

    return toLocalizedUrl('/ping', preferredLanguageCode);
};

export const updateEnvironmentHash =
    () => async (dispatch: AppDispatch, getState: GetState) => {
        const url = getVersionUrl(getState);
        const response = await axios.get(url);
        const environmentHash = response.headers['x-tradera-environment'];
        const current = getState().environment.environmentHash;
        if (environmentHash !== current) {
            dispatch(setEnvironmentHash(environmentHash));
            dispatch(setIsSpaNavigationEnabled(false));
        }
    };

const getAndUpdateNativeAppInfo = (initData: Record<string, unknown>) => {
    const nativeAppInfoFromInitData =
        extractNativeAppInfoFromInitData(initData);
    const hasAnyValidProperties =
        Object.values(nativeAppInfoFromInitData).filter(Boolean).length > 0;
    if (hasAnyValidProperties) {
        const nativeAppCookieValue = createNativeAppCookieValue(
            nativeAppInfoFromInitData
        );
        cookie.createCookie(NATIVE_APP_ENVIRONMENT, nativeAppCookieValue);
        return nativeAppInfoFromInitData;
    }

    const cookieValue = cookie.readCookie(NATIVE_APP_ENVIRONMENT);
    const nativeAppInfo = extractNativeAppInfoFromCookie(cookieValue);
    return nativeAppInfo;
};

export const setupGATrackingData =
    () => (dispatch: AppDispatch, getState: GetState) => {
        const {
            environment: { variables }
        } = getState();
        const { PUBLIC_GOOGLE_ANALYTICS_MEASUREMENT_ID } = variables;

        if (PUBLIC_GOOGLE_ANALYTICS_MEASUREMENT_ID) {
            gtm.pushArguments(
                'get',
                PUBLIC_GOOGLE_ANALYTICS_MEASUREMENT_ID,
                'session_id',
                (sessionId: string) => {
                    dispatch(setGATrackingData({ sessionId }));
                }
            );
        }
    };

export const initEnvironment =
    (initData: {
        environment: string;
        environmentHash: string;
        featureSwitches: Record<string, boolean>;
        googleAnalyticsTrackingId: string;
        googleAnalyticsMeasurementId: string;
        geoPublicApiBaseUrl: string;
        isSinglePageApp: boolean;
        splitTests: Record<string, object>;
        splitTestGroups: Record<string, string>;
        version: string;
        webLiveUrl: string;
    }) =>
    async (dispatch: AppDispatch) => {
        const {
            environment,
            environmentHash,
            featureSwitches,
            googleAnalyticsTrackingId,
            googleAnalyticsMeasurementId,
            geoPublicApiBaseUrl,
            isSinglePageApp,
            splitTestGroups,
            version,
            webLiveUrl
        } = initData;

        const isSpaNavigationEnabled =
            isSinglePageApp && !window.frameElement ? true : false; // No SPA navigation in native apps or iFrame
        dispatch(setIsSpaNavigationEnabled(isSpaNavigationEnabled));
        dispatch(setEnvironment(environment));
        dispatch(setEnvironmentHash(environmentHash));
        dispatch(setFeatureSwitches(featureSwitches));
        dispatch(setSplitTestGroups(splitTestGroups));
        dispatch(setVersion(version));
        dispatch(
            setVariables({
                // Names must be the same as NextWeb's .env variables
                PUBLIC_GOOGLE_ANALYTICS_TRACKING_ID: googleAnalyticsTrackingId,
                PUBLIC_GOOGLE_ANALYTICS_MEASUREMENT_ID:
                    googleAnalyticsMeasurementId,
                PUBLIC_GEO_PUBLIC_API_BASE_URL: geoPublicApiBaseUrl,
                PUBLIC_WEB_LIVE_URL: webLiveUrl
            })
        );
        dispatch(
            setNativeAppInfo({
                ...getAndUpdateNativeAppInfo(initData),
                browserName,
                isMobileDevice: isMobile,
                isMobileSafari,
                isIOS,
                isAndroid
            })
        );

        dispatch(setupGATrackingData());
    };
