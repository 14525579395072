import { isLinkToNextWeb } from 'tradera/components/alink/is-link-to-next-web';
import { isLinkToTouchwebInNextWeb } from 'tradera/components/alink/is-link-to-touchweb-in-next-web';
import { URL_LANGUAGES } from 'tradera/lang/constants.mjs';
import { isNextJs, isTouchwebInNextweb } from 'tradera/utils/nextjs';

export enum NavigationType {
    WindowTopLocation = 'WindowTopLocation',
    WindowLocation = 'WindowLocation',
    NextSpaNavigation = 'NextSpaNavigation',
    TouchwebSpaNavigation = 'TouchwebSpaNavigation'
}

enum ReactRouterType {
    MyTradera = 'MyTradera',
    Syi = 'Syi',
    Tradera = 'Tradera'
}

const langPrefix = `(/(${URL_LANGUAGES.join('|')}))?`;
const syiRegex = new RegExp(`^${langPrefix}/selling`);
const myTraderaRegex = new RegExp(`^${langPrefix}/my/`);

const getReactRouterType = (url: URL) => {
    if (myTraderaRegex.test(url.pathname)) {
        return ReactRouterType.MyTradera;
    } else if (syiRegex.test(url.pathname)) {
        return ReactRouterType.Syi;
    } else {
        return ReactRouterType.Tradera;
    }
};

type Target = 'top' | 'new' | 'self';

export const getNavigationType = ({
    fromUrl,
    toUrl,
    target = 'self',
    isTouchwebSpaLink,
    isNativeApp,
    toggles
}: {
    fromUrl: URL;
    toUrl: URL;
    target?: Target;
    isTouchwebSpaLink?: boolean;
    isNativeApp: boolean;
    toggles: Record<string, boolean>;
}) => {
    if (isNativeApp || target === 'new') {
        return NavigationType.WindowLocation;
    } else if (target === 'top') {
        return NavigationType.WindowTopLocation;
    }

    // We have 3 types of routing in the app:
    // 1. react-router-dom routing mounted in a SSR NextJS routed page
    const fromNextWebTouchwebHybrid = isTouchwebInNextweb;
    const toNextWebTouchwebHybrid = isLinkToTouchwebInNextWeb(toUrl, toggles);

    // 2. NextJS routing using NextLink
    const fromNextWeb = isNextJs && !isTouchwebInNextweb;
    const toNextWeb = isLinkToNextWeb(toUrl, fromUrl, toggles);

    // 3. react-router-dom routing
    const toTouchwebBySpaNavigation =
        !toNextWeb && !toNextWebTouchwebHybrid && isTouchwebSpaLink;

    if (fromNextWeb && toNextWeb) {
        const isCurrentPage =
            `${fromUrl.pathname}${fromUrl.search}` ===
            `${toUrl.pathname}${toUrl.search}`;

        // NextJS routing
        // SPA-navigation to the same page becomes a problem when a Redux slice is reset
        // and the data is not loaded because the URL is the same.
        // Reloading the whole page is the solution that fits all these edge cases.
        return isCurrentPage
            ? NavigationType.WindowLocation
            : NavigationType.NextSpaNavigation;
    } else if (
        (!fromNextWeb && toTouchwebBySpaNavigation) ||
        (fromNextWebTouchwebHybrid && toNextWebTouchwebHybrid)
    ) {
        // react-router-dom routing

        const fromUrlType = getReactRouterType(fromUrl);
        const toUrlType = getReactRouterType(toUrl);

        // Prevent page not found when navigating between different react-router-dom routers
        // Do a full page load between the routers SYI, MyTradera and Tradera SPAs
        if (fromUrlType !== toUrlType) {
            return NavigationType.WindowLocation;
        }

        return NavigationType.TouchwebSpaNavigation;
    }

    // Anything that ends up here will result in a full page load instead of SPA navigation.
    // Remaining 5 out of 9 combinations of navigations that end up in this else block:
    return NavigationType.WindowLocation;
};
