import * as Sentry from '@sentry/nextjs';
import { isNextJs, isServer } from 'tradera/utils/nextjs';
import type { InitOptions } from 'i18next';
import { isAttributeStuffing } from 'tradera/lang/helpers';

export const missingKeyLoggingConfig = <InitOptions>{
    saveMissing: true,
    saveMissingTo: 'current' as const,
    missingKeyHandler: (
        language,
        namespace,
        key,
        _fallbackValue,
        _updateMissing,
        options
    ) => {
        if (
            (!isNextJs ||
                isServer ||
                (isNextJs && !isServer && options.hasLoadedAllTranslations)) &&
            !isAttributeStuffing(key, namespace)
        ) {
            const errorMessage = `TranslationMissing (javascript) - Unable to find key ${key} (namespace ${namespace}) in language ${language}!`;
            Sentry.captureException(new Error(errorMessage));
        }
    },
    missingInterpolationHandler: (text, value) => {
        Sentry.captureException(
            new Error(
                `InterpolationFailure (javascript) - Unable to interpolate the text "${text}" fully. The following placeholder values were missing: ${JSON.stringify(
                    value
                )}`
            )
        );
        return undefined;
    }
};
