import {
    getFirstHeaderValueOrEmpty,
    getMemberIdFromAuthHeader
} from 'tradera/backend/utils/headers';
import { dangerouslyParseJwtWithoutVerifyingSignature } from 'tradera/backend/utils/jwt';
import { isProductionHost } from 'tradera/helpers/host';
import {
    type CookieSerializeOptions,
    serialize as serializeCookie
} from 'cookie';
import type { Request, Response } from '../http/types/http';
import { CART_ID } from 'tradera/constants/cookies';

export const mightBeLoggedIn = (req: {
    cookies: Partial<{
        [key: string]: string;
    }>;
    headers: NodeJS.Dict<string | string[]>;
}) =>
    Boolean(req.cookies[getRefreshTokenCookieName(req)]) ||
    getMemberIdFromAuthHeader(req) ||
    getMemberIdFromAuthCookie(req);

export const getAccessTokenCookieDomain = (req: {
    cookies: Partial<{
        [key: string]: string;
    }>;
    headers: NodeJS.Dict<string | string[]>;
}) => {
    // The trd_at cookie is set for subdomains of tradera.com.
    // It is sent in requests to tradera.com, www.tradera.com, info.tradera.com etc.

    const host = getFirstHeaderValueOrEmpty(req, 'host').split(':')[0];
    const isIp = /^\d+\.\d+\.\d+\.\d+$/.test(host);
    const canHaveSubdomain = !isIp && host.split('.').length > 1; // tradera.com, www.tradera.com
    const hasSubdomain = canHaveSubdomain && host.split('.').length > 2; // NOT tradera.com

    if (hasSubdomain) {
        return host.replace(/^[^.]+\./, '.');
    }

    if (canHaveSubdomain) {
        return `.${host}`;
    }

    return undefined;
};

export const getRefreshTokenCookieDomain = () => undefined;

export const getAccessTokenCookieName = (req: {
    headers: NodeJS.Dict<string | string[]>;
}) =>
    isProductionHost(getFirstHeaderValueOrEmpty(req, 'host'))
        ? 'trd_at'
        : 'trd_at_test';

export const getRefreshTokenCookieName = (req: {
    headers: NodeJS.Dict<string | string[]>;
}) =>
    isProductionHost(
        typeof req.headers.host === 'string' ? req.headers.host : ''
    )
        ? 'trd_rt'
        : 'trd_rt_test';

export const getPersistTokenCookieName = (req: {
    headers: NodeJS.Dict<string | string[]>;
}) =>
    isProductionHost(getFirstHeaderValueOrEmpty(req, 'host'))
        ? 'trd_rk'
        : 'trd_rk_test';

export const getMemberIdFromAuthCookie = (req: {
    cookies: Partial<{
        [key: string]: string;
    }>;
    headers: NodeJS.Dict<string | string[]>;
}) =>
    dangerouslyParseJwtWithoutVerifyingSignature(
        req.cookies?.[getAccessTokenCookieName(req)]
    )?.mbr;

export const getExpiryFromAuthCookie = (req: {
    cookies: Partial<{
        [key: string]: string;
    }>;
    headers: NodeJS.Dict<string | string[]>;
}) =>
    dangerouslyParseJwtWithoutVerifyingSignature(
        req.cookies?.[getAccessTokenCookieName(req)]
    )?.exp;

export const isImpersonation = (req: {
    cookies: Partial<{
        [key: string]: string;
    }>;
    headers: NodeJS.Dict<string | string[]>;
}) =>
    !!dangerouslyParseJwtWithoutVerifyingSignature(
        req.cookies?.[getAccessTokenCookieName(req)]
    )?.imp;

export const isTokenExpiringSoon = (token?: string) => {
    const parsedToken = dangerouslyParseJwtWithoutVerifyingSignature(token);
    const { exp: expiry = undefined } = parsedToken || {};
    if (!expiry) {
        return true;
    }
    const now = new Date();
    const expiryDate = new Date(
        // The new format is in Unix time. Legacy is a UTC-string.
        // We support both.
        Number.isInteger(expiry) ? expiry * 1000 : expiry
    );
    const expiresSoonDate = new Date(expiryDate.getTime() - 60 * 1000);
    const isExpired = expiresSoonDate <= now;
    return isExpired;
};

export const addCookie = (
    res: {
        getHeader: (name: string) => string | number | string[] | undefined;
        setHeader: (
            name: string,
            value: string | number | readonly string[]
        ) => unknown;
    },
    cookie: string
) => {
    const previouslySetCookies = res.getHeader('Set-Cookie');
    if (Array.isArray(previouslySetCookies)) {
        res.setHeader('Set-Cookie', [...previouslySetCookies, cookie]);
    } else if (previouslySetCookies) {
        res.setHeader('Set-Cookie', [`${previouslySetCookies}`, cookie]);
    } else {
        res.setHeader('Set-Cookie', cookie);
    }
};

const DAY_IN_MILLISECONDS = 1000 * 60 * 60 * 24;
const YEAR_IN_MILLISECONDS = DAY_IN_MILLISECONDS * 365;
const MONTH_IN_MILLISECONDS = DAY_IN_MILLISECONDS * 30;
const CART_COOKIE_EXPIRATION_IN_MILLISECONDS = MONTH_IN_MILLISECONDS * 2;

export type CookieConfiguration = {
    name: string;
    value: string;
    options: CookieSerializeOptions;
};

export const configureCookie = (
    req: Request,
    name: string,
    value: string,
    domain?: string,
    isPersistent?: boolean,
    isRemove?: boolean
): CookieConfiguration => {
    const isSecureRequest = req.headers['x-forwarded-proto'] !== 'http';
    const options: CookieSerializeOptions = {
        httpOnly: true,
        domain,
        path: '/',
        secure: isSecureRequest,
        sameSite: 'lax'
    };
    if (isRemove) {
        options.expires = new Date(0);
    } else if (isPersistent) {
        options.expires = new Date(Date.now() + YEAR_IN_MILLISECONDS);
    }
    return {
        name,
        value,
        options
    };
};

export const setCartIdCookie = (
    req: Request,
    res: Response,
    cartId: string
) => {
    const options: CookieSerializeOptions = {
        httpOnly: false, // Is read by JavaScript in browser.
        domain: undefined, // Current domain only
        path: '/',
        secure: req.headers['x-forwarded-proto'] !== 'http',
        sameSite: false,
        expires: new Date(Date.now() + CART_COOKIE_EXPIRATION_IN_MILLISECONDS)
    };
    addCookie(res, serializeCookie(CART_ID, cartId, options));
};

export const setCookies = (
    res: Response,
    cookieConfigurations: CookieConfiguration[]
) => {
    cookieConfigurations.forEach(({ name, value, options }) =>
        addCookie(res, serializeCookie(name, String(value), options))
    );
};
