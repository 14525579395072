import { isSupportedMinimumVersion } from 'tradera/utils/versions';

export const getNativeAppSupport = ({
    isNativeAppContext,
    isHybridAppContext,
    hybridAppDevice,
    hybridAppVersion,
    appOsVersion,
    isIOS,
    isAndroid
}) => {
    const isHybridAppContextForAndroid = Boolean(
        isHybridAppContext &&
            (hybridAppDevice?.toLowerCase().includes('android') || isAndroid)
    );
    const isHybridAppContextForIos = Boolean(
        isHybridAppContext &&
            (hybridAppDevice?.toLowerCase().includes('iphone') ||
                hybridAppDevice?.toLowerCase().includes('ipad') ||
                isIOS)
    );
    const isIos13 =
        isNativeAppContext &&
        isHybridAppContextForIos &&
        isSupportedMinimumVersion(appOsVersion, '13');
    return {
        isHybridAppContextForAndroid,
        isHybridAppContextForIos,
        isNativeAppWithApplyPaySupport:
            isIos13 && isSupportedMinimumVersion(hybridAppVersion, '3.51'),
        isNativeAppWithSwishCallbackSupport:
            isIos13 && isSupportedMinimumVersion(hybridAppVersion, '3.57'),
        isNativeAppWithKlarnaSupport:
            isNativeAppContext &&
            ((isIOS && isSupportedMinimumVersion(hybridAppVersion, '3.68')) ||
                (isAndroid &&
                    isSupportedMinimumVersion(hybridAppVersion, '3.58'))),
        isNativeAppWithTrustlySupport:
            isNativeAppContext &&
            ((isIOS && isSupportedMinimumVersion(hybridAppVersion, '3.123')) ||
                (isAndroid &&
                    isSupportedMinimumVersion(hybridAppVersion, '3.87'))),
        isNativeAppWithInternationalVerificationSupport:
            isNativeAppContext &&
            ((isIOS && isSupportedMinimumVersion(hybridAppVersion, '3.156')) ||
                (isAndroid &&
                    isSupportedMinimumVersion(hybridAppVersion, '3.999'))) // TODO: Update version when implemented
    };
};
