const isTraderaDevHostname = (hostname: string): boolean =>
    /\.tradera\.dev$/.test(hostname);

const isWwwProxyDevHostname = (hostname: string): boolean =>
    /^www-proxy\..*\.tradera\.service$/.test(hostname); // www-proxy.dev-web.tradera.service

export const isDevHost = (host: string) =>
    isTraderaDevHostname(host) || isWwwProxyDevHostname(host);

export const isLocalHost = (host: string) =>
    [
        /webpack-internal:\/\//,
        /^local(host)?(:[0-9]+)*$/,
        /^(www\.)tradera.local?(:[0-9]+)*$/,
        /^host\.docker\.internal(:[0-9]+)*$/,
        /127\.0\.0\.1:\d+/,
        /10\.\d+\.\d+\.\d+:\d+/,
        /192\.168.\.\d+\.\d+:\d+/
    ].some((regex) => regex.test(host));

export const isProductionHost = (host: string) =>
    !isLocalHost(host) && !isDevHost(host);
