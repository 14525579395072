import { useNavigation } from 'tradera/hooks/use-navigation';
import { useLocation } from 'tradera/hooks/use-location';
import { useCallback } from 'react';
import { isNullOrUndefined } from 'tradera/utils/condition-check';

export enum ROUTING_TYPE {
    URL = 'url',
    PUSH = 'push',
    REPLACE = 'replace'
}

export type UpdateSearchParams = (
    value?: string | number | boolean | null | undefined,
    routing?: ROUTING_TYPE,
    locationParameter?: URL
) => URL;

// This is TS overloading, meaning that the function can be called in different ways.
// If called with a parameter, if will return a string or null.
// If called with a defaultValue parameter, it will return a string or the defaultValue.
/**
 * @deprecated
 * This is deprecated - use src/apps/nextweb/hooks/use-query-param.ts instead in Next.js
 *
 * If a parameter is passed, it returns an array with the value of the parameter and a function to update the value.
 * By default, the update function will trigger a `navigation.push()` call, but that can be disabled by passing `false` as the second parameter.
 *
 * If not parameter is passed, it returns a URLSearchParams object.
 *
 * @example
 * const [pageQuery, setPageQuery] = useQuery('page', 1);
 * const [filterQuery, setFilterQuery] = useQuery('filter', 'all');
 *
 * const handleFilterChange = (newFilter) => {
 *   const url = setPageQuery(null, false);
 *   setFilterQuery(newFilter, true, url);
 * };
 */
export function useQuery(
    parameter: string,
    defaultValue: string | number | boolean
): [string, UpdateSearchParams];
export function useQuery(
    parameter: string
): [string | null, UpdateSearchParams];
export function useQuery(
    parameter: string,
    defaultValue?: string | number | boolean
): [string | null, UpdateSearchParams] {
    const location = useLocation('https://example.com');
    const navigation = useNavigation(true);
    const searchParams = new URLSearchParams(location.search);

    /**
     * Updates the value of the parameter in the URL.
     * If no value is passed, it will remove the parameter from the URL.
     *
     * By default, it will trigger a `navigation.push()` call, but that can be disabled by passing `false` as the second parameter.
     * By default it will use the current location, but that can be overridden by passing a URL as the third parameter.
     */
    const updateSearchParam: UpdateSearchParams = useCallback(
        (value, routing = ROUTING_TYPE.PUSH, locationParameter) => {
            const url = new URL(locationParameter ?? location);
            if (!parameter) return url;

            if (
                typeof value !== 'undefined' &&
                value !== null &&
                value !== defaultValue
            ) {
                url.searchParams.set(parameter, value.toString());
            } else {
                url.searchParams.delete(parameter);
            }

            if (routing !== ROUTING_TYPE.URL) navigation[routing](url);

            return url;
        },

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [parameter]
    );

    return [
        searchParams.get(parameter) ?? toStringOrNull(defaultValue),
        updateSearchParam
    ];
}

// this is to avoid stringifying undefined to "undefined"
const toStringOrNull = (
    value: string | number | boolean | undefined | null
): string | null => (isNullOrUndefined(value) ? null : value.toString());
