import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useRouter } from 'next/compat/router';
import { isNextJs, isServer, isTouchwebInNextweb } from 'tradera/utils/nextjs';
import { removePrivacySandboxIframes } from 'tradera/utils/privacy-sandbox-iframes';
import { useAppSelector } from 'tradera/state/hooks';
import {
    selectFeatureSwitches,
    selectIsNativeApp
} from 'tradera/state/environment/selectors';
import {
    getNavigationType,
    NavigationType
} from 'tradera/components/alink/navigation-type';
import { useLocation } from 'tradera/hooks/use-location';

export type PushConfig = {
    breakIframe?: boolean;
};

export type PushLocation = {
    pathname?: string | number;
    search?: string;
};

const hasPathname = (obj: unknown): obj is { pathname: string | number } =>
    obj !== null &&
    typeof obj === 'object' &&
    'pathname' in obj &&
    typeof obj.pathname !== 'undefined';

const hasSearch = (obj: unknown): obj is { search: string } =>
    obj !== null &&
    typeof obj === 'object' &&
    'search' in obj &&
    typeof obj.search !== 'undefined';

export const toUrlString = (location: PushLocation | string) => {
    if (typeof location === 'string') {
        return location;
    } else if (hasPathname(location) && hasSearch(location)) {
        return `${location.pathname}${location.search}`;
    } else if (hasPathname(location)) {
        return `${location.pathname}`;
    } else if (hasSearch(location)) {
        return `${location.search}`;
    }
    return '';
};

export const useNavigation = (isNextDataFetchingDisabled?: boolean) => {
    const nextRouter = useRouter();
    const touchwebHistory = useHistory();
    const isNativeApp = useAppSelector(selectIsNativeApp);
    const toggles = useAppSelector(selectFeatureSwitches);
    const currentLocation = useLocation('https://www.tradera.com');

    return useMemo(
        () => ({
            push: (
                location: PushLocation | string,
                pushConfig?: PushConfig
            ) => {
                removePrivacySandboxIframes();

                const url = toUrlString(location);
                const urlObject = new URL(url, window.location.origin);
                const target =
                    pushConfig?.breakIframe &&
                    window.self !== window.top &&
                    window.top !== null
                        ? 'top'
                        : 'self';

                const navigationType = getNavigationType({
                    fromUrl: currentLocation,
                    toUrl: urlObject,
                    target,
                    isTouchwebSpaLink: true,
                    isNativeApp,
                    toggles
                });

                switch (navigationType) {
                    case NavigationType.WindowTopLocation:
                        if (window.top !== null) {
                            window.top.location.href = url.toString();
                        } else {
                            window.location.href = url.toString();
                        }
                        return;
                    case NavigationType.WindowLocation:
                        window.location.href = url.toString();
                        return;
                    case NavigationType.NextSpaNavigation:
                        return nextRouter?.push(url, undefined, {
                            shallow: isNextDataFetchingDisabled
                        });
                    case NavigationType.TouchwebSpaNavigation:
                        return touchwebHistory.push(url);
                    default:
                        throw new Error(
                            `Unknown navigation type in useNavigation hook: ${navigationType}`
                        );
                }
            },
            replace: (location: PushLocation | string) => {
                const url = toUrlString(location);
                if (isNextJs && !isTouchwebInNextweb) {
                    return nextRouter?.replace(url, undefined, {
                        shallow: isNextDataFetchingDisabled
                    });
                }
                return touchwebHistory.replace(url);
            },
            back: () => {
                if (isNativeApp) {
                    window.history.back();
                    return;
                } else if (isNextJs && !isTouchwebInNextweb) {
                    return nextRouter?.back();
                }
                return touchwebHistory.goBack();
            },
            refresh: () => {
                if (isNextJs && !isTouchwebInNextweb) {
                    // Trigger fetching data from getServerSideProps().
                    return nextRouter?.replace(window.location, undefined, {
                        shallow: isNextDataFetchingDisabled
                    });
                }
                throw new Error('This is only for NextWeb!');
            },
            hasPagesInHistory: () => !isServer && window.history.length > 1
        }),
        [
            isNativeApp,
            nextRouter,
            isNextDataFetchingDisabled,
            touchwebHistory,
            currentLocation,
            toggles
        ]
    );
};
