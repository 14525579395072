import cookie from 'cookie';
import type { IncomingHttpHeaders } from 'http';
import { addCookie } from 'tradera-backend/utils/cookies';
import type { Request, Response } from 'tradera/backend/http/types/http';
import { NATIVE_APP_ENVIRONMENT } from 'tradera/constants/cookies';
import {
    DEFAULT_LANGUAGE,
    SUPPORTED_LANGUAGES
} from 'tradera/lang/constants.mjs';
import { safeDecodeURIComponent } from 'tradera/utils/safe-decode-uri-component';

export type NativeAppInfo = {
    osVersion?: string;
    appDevice?: string;
    appVersion?: string;
    appContext?: string;
    appLanguage?: string;
    appInstanceId?: string;
};

type NativeAppCookieAndHeaderConfig = {
    [P in keyof NativeAppInfo]: {
        header: string;
        initDataName: string;
        normalize?: (value: string) => string;
    };
};

const NATIVE_APP_COOKIE_AND_HEADER_CONFIG: NativeAppCookieAndHeaderConfig = {
    osVersion: {
        header: 'x-tradera-app-os-version',
        initDataName: 'nativeAppOsVersion'
    },
    appDevice: {
        header: 'x-tradera-app-device',
        initDataName: 'nativeAppDevice'
    },
    appVersion: {
        header: 'x-tradera-app-version',
        initDataName: 'nativeAppVersion'
    },
    appContext: { header: 'x-tradera-app', initDataName: 'nativeAppContext' },
    appLanguage: {
        header: 'x-language',
        initDataName: 'nativeAppLanguage'
    },
    appInstanceId: {
        header: 'X-Tradera-App-GA-App-Instance-Id',
        initDataName: 'nativeAppInstanceId'
    }
};

const normalizeLanguage = (language?: string) => {
    if (typeof language !== 'string' || language.length === 0) {
        return '';
    }

    if (!SUPPORTED_LANGUAGES.includes(language)) {
        return DEFAULT_LANGUAGE;
    }

    return language;
};

const createCookie = (name: string, value: string) => {
    const options = {
        httpOnly: false,
        path: '/'
    };
    return cookie.serialize(name, String(value), options);
};

const isString = (s: unknown) => typeof s === 'string';

export const createNativeAppCookieValue = (
    nativeAppInfo: Record<string, unknown>
) => {
    const valueObject = Object.fromEntries(
        Object.entries(nativeAppInfo).filter(
            ([key, value]) =>
                key in NATIVE_APP_COOKIE_AND_HEADER_CONFIG && isString(value)
        )
    );
    return JSON.stringify(valueObject);
};

export const createNativeAppCookie = (
    nativeAppInfo: Record<string, string>
) => {
    return createCookie(
        NATIVE_APP_ENVIRONMENT,
        createNativeAppCookieValue(nativeAppInfo)
    );
};

/**
 * Gets info about the native app if in native app context.
 * The function also updates a cookie with the same information
 * to be used for later calls to this function if the headers are
 * not present.
 *
 * @param {*} req
 * @param {*} res
 * @returns An object with these properties: {
        osVersion,
        appDevice,
        appVersion,
        appContext
    }
 */
export const getAndUpdateNativeAppInfo = (
    req: Request,
    res: Response
): NativeAppInfo => {
    const nativeAppInfoFromHeaders = extractNativeAppInfoFromHeaders(
        req.headers
    );

    const hasAppHeaders =
        Object.values(nativeAppInfoFromHeaders).filter(Boolean).length > 0;
    if (hasAppHeaders) {
        const cookie = createNativeAppCookie(nativeAppInfoFromHeaders);
        addCookie(res, cookie);
        return nativeAppInfoFromHeaders;
    }

    const nativeAppInfoFromCookie = extractNativeAppInfoFromCookie(
        req.cookies[NATIVE_APP_ENVIRONMENT]
    );
    return nativeAppInfoFromCookie;
};

const filterNativeAppInfo = (
    entry: [string, unknown]
): entry is [keyof NativeAppCookieAndHeaderConfig, string] => {
    const [key] = entry;
    return (
        typeof key === 'string' && key in NATIVE_APP_COOKIE_AND_HEADER_CONFIG
    );
};

const normalizeMapNativeAppInfo = ([key, value]: [
    keyof NativeAppCookieAndHeaderConfig,
    string
]) => {
    const normalizedValue =
        key === 'appLanguage' ? normalizeLanguage(value) : value;
    return [key, normalizedValue];
};

export const extractNativeAppInfoFromCookie = (
    nativeAppCookieString: string | null | undefined
): NativeAppInfo =>
    nativeAppCookieString
        ? Object.fromEntries(
              Object.entries(
                  JSON.parse(safeDecodeURIComponent(nativeAppCookieString))
              )
                  .filter(filterNativeAppInfo)
                  .map(normalizeMapNativeAppInfo)
          )
        : {};

export const extractNativeAppInfoFromHeaders = (
    headers: IncomingHttpHeaders
) => {
    return Object.entries(NATIVE_APP_COOKIE_AND_HEADER_CONFIG).reduce(
        (obj: Record<string, string>, [key, config]) => {
            const header = headers[config.header];
            const singleValue = Array.isArray(header) ? header[0] : header;
            const normalizedValue =
                key === 'appLanguage'
                    ? normalizeLanguage(singleValue)
                    : singleValue;
            obj[key] = normalizedValue || '';
            return obj;
        },
        {}
    );
};

export const extractNativeAppInfoFromInitData = (
    initData: Record<string, unknown>
) => {
    return Object.entries(NATIVE_APP_COOKIE_AND_HEADER_CONFIG).reduce(
        (obj: Record<string, unknown>, [key, config]) => {
            obj[key] = initData[config.initDataName] || '';
            return obj;
        },
        {}
    );
};
