'use client';

/* eslint-disable @typescript-eslint/no-explicit-any */
// This is a cleaned-up ES6 copy of the next/link component without prefetch from:
// https://github.com/vercel/next.js/blob/v12.2.5/packages/next/client/link.tsx
// This component was mainly copied to completely disable prefetch on link mouse-over
// Update this component if needed when updating Next.js

import type { NextRouter } from 'next/dist/shared/lib/router/router';

import React from 'react';
import type { UrlObject } from 'url';
import { resolveHref } from 'next/dist/client/resolve-href';
import { isLocalURL } from 'next/dist/shared/lib/router/utils/is-local-url';
import { formatUrl } from 'next/dist/shared/lib/router/utils/format-url';
import { isAbsoluteUrl } from 'next/dist/shared/lib/utils';
import { addLocale } from 'next/dist/client/add-locale';
import { RouterContext } from 'next/dist/shared/lib/router-context.shared-runtime';
import { AppRouterContext } from 'next/dist/shared/lib/app-router-context.shared-runtime';
import type { AppRouterInstance } from 'next/dist/shared/lib/app-router-context.shared-runtime';
import { useIntersection } from 'next/dist/client/use-intersection';
import { getDomainLocale } from 'next/dist/client/get-domain-locale';
import { addBasePath } from 'next/dist/client/add-base-path';

type Url = string | UrlObject;
type RequiredKeys<T> = {
    // eslint-disable-next-line @typescript-eslint/no-empty-object-type
    [K in keyof T]-?: {} extends Pick<T, K> ? never : K;
}[keyof T];
type OptionalKeys<T> = {
    // eslint-disable-next-line @typescript-eslint/no-empty-object-type
    [K in keyof T]-?: {} extends Pick<T, K> ? K : never;
}[keyof T];

type InternalLinkProps = {
    /**
     * The path or URL to navigate to. It can also be an object.
     *
     * @example https://nextjs.org/docs/api-reference/next/link#with-url-object
     */
    href: Url;
    /**
     * Optional decorator for the path that will be shown in the browser URL bar. Before Next.js 9.5.3 this was used for dynamic routes, check our [previous docs](https://nextjs.org/docs/tag/v9.5.2/api-reference/next/link#dynamic-routes) to see how it worked. Note: when this path differs from the one provided in `href` the previous `href`/`as` behavior is used as shown in the [previous docs](https://nextjs.org/docs/tag/v9.5.2/api-reference/next/link#dynamic-routes).
     */
    as?: Url;
    /**
     * Replace the current `history` state instead of adding a new url into the stack.
     *
     * @defaultValue `false`
     */
    replace?: boolean;
    /**
     * Whether to override the default scroll behavior
     *
     * @example https://nextjs.org/docs/api-reference/next/link#disable-scrolling-to-the-top-of-the-page
     *
     * @defaultValue `true`
     */
    scroll?: boolean;
    /**
     * Update the path of the current page without rerunning [`getStaticProps`](/docs/basic-features/data-fetching/get-static-props.md), [`getServerSideProps`](/docs/basic-features/data-fetching/get-server-side-props.md) or [`getInitialProps`](/docs/api-reference/data-fetching/get-initial-props.md).
     *
     * @defaultValue `false`
     */
    shallow?: boolean;
    /**
     * Forces `Link` to send the `href` property to its child.
     *
     * @defaultValue `false`
     */
    passHref?: boolean;
    /**
     * The active locale is automatically prepended. `locale` allows for providing a different locale.
     * When `false` `href` has to include the locale as the default behavior is disabled.
     */
    locale?: string | false;
    /**
     * Optional event handler for when the mouse pointer is moved onto Link
     */
    onMouseEnter?: React.MouseEventHandler<HTMLAnchorElement>;
    /**
     * Optional event handler for when Link is touched.
     */
    onTouchStart?: React.TouchEventHandler<HTMLAnchorElement>;
    /**
     * Optional event handler for when Link is clicked.
     */
    onClick?: React.MouseEventHandler<HTMLAnchorElement>;
};

// TODO-APP: Include the full set of Anchor props
// adding this to the publicly exported type currently breaks existing apps

// `RouteInferType` is a stub here to avoid breaking `typedRoutes` when the type
// isn't generated yet. It will be replaced when the webpack plugin runs.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type LinkProps<RouteInferType = any> = InternalLinkProps;
type LinkPropsRequired = RequiredKeys<LinkProps>;
type LinkPropsOptional = OptionalKeys<InternalLinkProps>;

function isModifiedEvent(event: React.MouseEvent): boolean {
    const eventTarget = event.currentTarget as HTMLAnchorElement | SVGAElement;
    const target = eventTarget.getAttribute('target');
    return (
        (target && target !== '_self') ||
        event.metaKey || // open in new tab on macOS
        event.ctrlKey || // right-click on macOS, open in new tab on Windows
        event.shiftKey || // open in new window
        event.altKey || // triggers resource download
        (event.nativeEvent && event.nativeEvent.which === 2)
    );
}

function linkClicked(
    e: React.MouseEvent,
    router: NextRouter | AppRouterInstance,
    href: string,
    as: string,
    replace?: boolean,
    shallow?: boolean,
    scroll?: boolean,
    locale?: string | false,
    isAppRouter?: boolean
): void {
    const { nodeName } = e.currentTarget;

    // anchors inside an svg have a lowercase nodeName
    const isAnchorNodeName = nodeName.toUpperCase() === 'A';

    if (
        isAnchorNodeName &&
        (isModifiedEvent(e) ||
            // app-router supports external urls out of the box so it shouldn't short-circuit here as support for e.g. `replace` is added in the app-router.
            (!isAppRouter && !isLocalURL(href)))
    ) {
        // ignore click for browser’s default behavior
        return;
    }

    e.preventDefault();

    const navigate = () => {
        // If the router is an NextRouter instance it will have `beforePopState`
        const routerScroll = scroll ?? true;
        if ('beforePopState' in router) {
            router[replace ? 'replace' : 'push'](href, as, {
                shallow,
                locale,
                scroll: routerScroll
            });
        } else {
            router[replace ? 'replace' : 'push'](as || href, {
                scroll: routerScroll
            });
        }
    };

    if (isAppRouter) {
        React.startTransition(navigate);
    } else {
        navigate();
    }
}

type LinkPropsReal = React.PropsWithChildren<
    Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof LinkProps> &
        LinkProps
>;

function formatStringOrUrl(urlObjOrString: UrlObject | string): string {
    if (typeof urlObjOrString === 'string') {
        return urlObjOrString;
    }

    return formatUrl(urlObjOrString);
}

function createPropError(args: {
    key: string;
    expected: string;
    actual: string;
}) {
    return new Error(
        `Failed prop type: The prop \`${args.key}\` expects a ${args.expected} in \`<Link>\`, but got \`${args.actual}\` instead.` +
            (typeof window !== 'undefined'
                ? "\nOpen your browser's console to view the Component stack trace."
                : '')
    );
}

/**
 * React Component that enables client-side transitions between routes.
 */
const Link = React.forwardRef<HTMLAnchorElement, LinkPropsReal>(
    function LinkComponent(props, forwardedRef) {
        if (process.env.NODE_ENV !== 'production') {
            // TypeScript trick for type-guarding:
            const requiredPropsGuard: Record<LinkPropsRequired, true> = {
                href: true
            } as const;
            const requiredProps: LinkPropsRequired[] = Object.keys(
                requiredPropsGuard
            ) as LinkPropsRequired[];
            requiredProps.forEach((key: LinkPropsRequired) => {
                if (key === 'href') {
                    if (
                        props[key] == null ||
                        (typeof props[key] !== 'string' &&
                            typeof props[key] !== 'object')
                    ) {
                        throw createPropError({
                            key,
                            expected: '`string` or `object`',
                            actual:
                                props[key] === null ? 'null' : typeof props[key]
                        });
                    }
                } else {
                    // TypeScript trick for type-guarding:
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const _: never = key;
                }
            });

            // TypeScript trick for type-guarding:
            const optionalPropsGuard: Record<LinkPropsOptional, true> = {
                as: true,
                replace: true,
                scroll: true,
                shallow: true,
                passHref: true,
                locale: true,
                onClick: true,
                onMouseEnter: true,
                onTouchStart: true
            } as const;
            const optionalProps: LinkPropsOptional[] = Object.keys(
                optionalPropsGuard
            ) as LinkPropsOptional[];
            optionalProps.forEach((key: LinkPropsOptional) => {
                const valType = typeof props[key];

                if (key === 'as') {
                    if (
                        props[key] &&
                        valType !== 'string' &&
                        valType !== 'object'
                    ) {
                        throw createPropError({
                            key,
                            expected: '`string` or `object`',
                            actual: valType
                        });
                    }
                } else if (key === 'locale') {
                    if (props[key] && valType !== 'string') {
                        throw createPropError({
                            key,
                            expected: '`string`',
                            actual: valType
                        });
                    }
                } else if (
                    key === 'onClick' ||
                    key === 'onMouseEnter' ||
                    key === 'onTouchStart'
                ) {
                    if (props[key] && valType !== 'function') {
                        throw createPropError({
                            key,
                            expected: '`function`',
                            actual: valType
                        });
                    }
                } else if (
                    key === 'replace' ||
                    key === 'scroll' ||
                    key === 'shallow' ||
                    key === 'passHref'
                ) {
                    if (props[key] != null && valType !== 'boolean') {
                        throw createPropError({
                            key,
                            expected: '`boolean`',
                            actual: valType
                        });
                    }
                } else {
                    // TypeScript trick for type-guarding:
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const _: never = key;
                }
            });
        }

        const {
            href: hrefProp,
            as: asProp,
            children: childrenProp,
            passHref,
            replace,
            shallow,
            scroll,
            locale,
            onClick,
            onMouseEnter: onMouseEnterProp,
            onTouchStart: onTouchStartProp,
            ...restProps
        } = props;

        const children: React.ReactNode = childrenProp;

        const pagesRouter = React.useContext(RouterContext);
        const appRouter = React.useContext(AppRouterContext);
        const router = pagesRouter ?? appRouter;

        // We're in the app directory if there is no pages router.
        const isAppRouter = !pagesRouter;

        if (process.env.NODE_ENV !== 'production') {
            if (isAppRouter && !asProp) {
                let href: string | undefined;
                if (typeof hrefProp === 'string') {
                    href = hrefProp;
                } else if (
                    typeof hrefProp === 'object' &&
                    typeof hrefProp.pathname === 'string'
                ) {
                    href = hrefProp.pathname;
                }

                if (href) {
                    const hasDynamicSegment = href
                        .split('/')
                        .some(
                            (segment) =>
                                segment.startsWith('[') && segment.endsWith(']')
                        );

                    if (hasDynamicSegment) {
                        throw new Error(
                            `Dynamic href \`${href}\` found in <Link> while using the \`/app\` router, this is not supported. Read more: https://nextjs.org/docs/messages/app-dir-dynamic-href`
                        );
                    }
                }
            }
        }

        const { href, as } = React.useMemo(() => {
            if (!pagesRouter) {
                const resolvedHref = formatStringOrUrl(hrefProp);
                return {
                    href: resolvedHref,
                    as: asProp ? formatStringOrUrl(asProp) : resolvedHref
                };
            }

            const [resolvedHref, resolvedAs] = resolveHref(
                pagesRouter,
                hrefProp,
                true
            );

            return {
                href: resolvedHref,
                as: asProp
                    ? resolveHref(pagesRouter, asProp)
                    : resolvedAs || resolvedHref
            };
        }, [pagesRouter, hrefProp, asProp]);

        const previousHref = React.useRef<string>(href);
        const previousAs = React.useRef<string>(as);

        // This will return the first child, if multiple are provided it will throw an error
        let child: any;
        if (process.env.NODE_ENV === 'development') {
            if ((children as any)?.type === 'a') {
                throw new Error(
                    'Invalid <Link> with <a> child. Please remove <a>.\nLearn more: https://nextjs.org/docs/messages/invalid-new-link-with-extra-anchor'
                );
            }
        }

        const childRef: any = forwardedRef;

        const [setIntersectionRef, , resetVisible] = useIntersection({
            rootMargin: '200px'
        });

        const setRef = React.useCallback(
            (el: Element) => {
                // Before the link getting observed, check if visible state need to be reset
                if (
                    previousAs.current !== as ||
                    previousHref.current !== href
                ) {
                    resetVisible();
                    previousAs.current = as;
                    previousHref.current = href;
                }

                setIntersectionRef(el);
                if (childRef) {
                    if (typeof childRef === 'function') childRef(el);
                    else if (typeof childRef === 'object') {
                        childRef.current = el;
                    }
                }
            },
            [as, childRef, href, resetVisible, setIntersectionRef]
        );

        const childProps: {
            onTouchStart: React.TouchEventHandler<HTMLAnchorElement>;
            onMouseEnter: React.MouseEventHandler<HTMLAnchorElement>;
            onClick: React.MouseEventHandler<HTMLAnchorElement>;
            href?: string;
            ref?: any;
        } = {
            ref: setRef,
            onClick(e) {
                if (process.env.NODE_ENV !== 'production') {
                    if (!e) {
                        throw new Error(
                            `Component rendered inside next/link has to pass click event to "onClick" prop.`
                        );
                    }
                }

                if (typeof onClick === 'function') {
                    onClick(e);
                }

                if (!router) {
                    return;
                }

                if (e.defaultPrevented) {
                    return;
                }

                linkClicked(
                    e,
                    router,
                    href,
                    as,
                    replace,
                    shallow,
                    scroll,
                    locale,
                    isAppRouter
                );
            },
            onMouseEnter(e) {
                if (typeof onMouseEnterProp === 'function') {
                    onMouseEnterProp(e);
                }
            },
            onTouchStart(e) {
                if (typeof onTouchStartProp === 'function') {
                    onTouchStartProp(e);
                }
            }
        };

        // If child is an <a> tag and doesn't have a href attribute, or if the 'passHref' property is
        // defined, we specify the current 'href', so that repetition is not needed by the user.
        // If the url is absolute, we can bypass the logic to prepend the domain and locale.
        if (isAbsoluteUrl(as)) {
            childProps.href = as;
        } else if (
            passHref ||
            (child.type === 'a' && !('href' in child.props))
        ) {
            const curLocale =
                typeof locale !== 'undefined' ? locale : pagesRouter?.locale;

            // we only render domain locales if we are currently on a domain locale
            // so that locale links are still visitable in development/preview envs
            const localeDomain =
                pagesRouter?.isLocaleDomain &&
                getDomainLocale(
                    as,
                    curLocale,
                    pagesRouter?.locales,
                    pagesRouter?.domainLocales
                );

            childProps.href =
                localeDomain ||
                addBasePath(
                    addLocale(as, curLocale, pagesRouter?.defaultLocale)
                );
        }

        return (
            <a {...restProps} {...childProps}>
                {children}
            </a>
        );
    }
);

export { Link as NextLink };
