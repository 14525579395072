import { toLocalizedUrl } from 'tradera/utils/url';
import { isServer } from 'tradera/utils/nextjs';

export const toLocalizedRiotUrl = (url) => toLocalizedUrl(url, getLanguage());

let language;
export const getLanguage = () => {
    if (isServer) {
        return;
    }
    if (language === '' || language === null || language === undefined) {
        // If we did this on the server in NextWeb all requests to the
        // same server would get the same language. This code is only
        // OK to run in the browser.
        // eslint-disable-next-line better-mutation/no-mutation
        language = document.querySelector('html').getAttribute('lang');
    }

    return language;
};
