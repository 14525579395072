/* eslint-disable better-mutation/no-mutation */

import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { NativeAppInfo } from 'tradera/state/environment/native-app-info-helper';
import { getNativeAppSupport } from 'tradera/state/environment/native-app-support';

type EnvironmentSlice = {
    isSpaNavigationEnabled: boolean;
    featureSwitches: Record<string, boolean>;
    splitTestGroups: Record<string, string>;
    variables: Record<string, string>;
    environmentHash: string | null;
    gaTrackingSessionId: string | null;
    isHybridAppContextForAndroid: boolean;
    isHybridAppContextForIos: boolean;
    isNativeAppWithApplyPaySupport: boolean;
    isNativeAppWithSwishCallbackSupport: boolean;
    isNativeAppWithKlarnaSupport: boolean;
    isNativeAppWithTrustlySupport: boolean;
    isNativeAppWithInternationalVerificationSupport: boolean;
    isHybridAppContext: boolean;
    appInstanceId: string | null;
    isIOS: boolean;
    isNativeAppContext?: boolean;
    isMobileDevice: boolean;
    isMobileSafari: boolean;
    version: string | null;
    browserName: string | null;
    appLanguage: string | null;
    appVersion: string | null;
    environment: string;
};

export const initialState: EnvironmentSlice = {
    featureSwitches: {},
    splitTestGroups: {},
    variables: {},
    isSpaNavigationEnabled: true,
    environment: process.env.NODE_ENV,
    version: null,
    environmentHash: null,
    gaTrackingSessionId: null,
    isHybridAppContextForAndroid: false,
    isHybridAppContextForIos: false,
    isNativeAppWithApplyPaySupport: false,
    isNativeAppWithSwishCallbackSupport: false,
    isNativeAppWithKlarnaSupport: false,
    isNativeAppWithTrustlySupport: false,
    isNativeAppWithInternationalVerificationSupport: false,
    isHybridAppContext: false,
    isIOS: false,
    appInstanceId: null,
    isMobileDevice: false,
    isMobileSafari: false,
    browserName: null,
    appLanguage: null,
    appVersion: null
};

const slice = createSlice({
    name: 'environment',
    initialState,
    reducers: {
        setEnvironmentHash: (state, { payload }) => {
            state.environmentHash = payload;
        },
        setEnvironment: (state, { payload }: PayloadAction<string>) => {
            state.environment = payload;
        },
        setIsSpaNavigationEnabled: (state, { payload }) => {
            state.isSpaNavigationEnabled = payload;
        },
        setGATrackingData: (state, { payload }) => {
            state.gaTrackingSessionId = payload.sessionId;
        },
        setFeatureSwitches: (
            state,
            { payload }: PayloadAction<Record<string, boolean>>
        ) => {
            state.featureSwitches = payload;
        },
        setSplitTestGroups: (
            state,
            { payload }: PayloadAction<Record<string, string>>
        ) => {
            state.splitTestGroups = payload;
        },
        setVariables: (
            state,
            { payload }: PayloadAction<Record<string, string>>
        ) => {
            state.variables = payload;
        },
        setVersion: (state, { payload }: PayloadAction<string>) => {
            state.version = payload;
        },
        setNativeAppInfo: (
            state,
            {
                payload: {
                    appContext,
                    appDevice,
                    appInstanceId,
                    appLanguage,
                    appVersion,
                    browserName,
                    osVersion,
                    isIOS,
                    isAndroid,
                    isMobileDevice,
                    isMobileSafari
                }
            }: PayloadAction<
                NativeAppInfo & {
                    browserName: string;
                    isMobileDevice: boolean;
                    isMobileSafari: boolean;
                    isIOS: boolean;
                    isAndroid: boolean;
                }
            >
        ) => {
            const isHybridAppContext = Boolean(appContext);
            const isNativeAppContext = appContext === 'Native';
            const nativeAppSupport = getNativeAppSupport({
                isNativeAppContext,
                isHybridAppContext,
                hybridAppDevice: appDevice,
                hybridAppVersion: appVersion,
                appOsVersion: osVersion,
                isIOS,
                isAndroid
            });

            state.isHybridAppContextForAndroid =
                nativeAppSupport.isHybridAppContextForAndroid;
            state.isHybridAppContextForIos =
                nativeAppSupport.isHybridAppContextForIos;
            state.isNativeAppWithApplyPaySupport =
                nativeAppSupport.isNativeAppWithApplyPaySupport;
            state.isNativeAppWithSwishCallbackSupport =
                nativeAppSupport.isNativeAppWithSwishCallbackSupport;
            state.isNativeAppWithKlarnaSupport =
                nativeAppSupport.isNativeAppWithKlarnaSupport;
            state.isNativeAppWithTrustlySupport =
                nativeAppSupport.isNativeAppWithTrustlySupport;
            state.isNativeAppWithInternationalVerificationSupport =
                nativeAppSupport.isNativeAppWithInternationalVerificationSupport;
            state.isHybridAppContext = isHybridAppContext;
            state.appInstanceId = appInstanceId || null;
            state.isIOS = isIOS;
            state.isMobileDevice = isMobileDevice;
            state.isMobileSafari = isMobileSafari;
            state.isNativeAppContext = isNativeAppContext;
            state.appLanguage = appLanguage || null;
            state.appVersion = appVersion || null;
            state.browserName = browserName;
        }
    }
});

export const {
    setEnvironment,
    setEnvironmentHash,
    setIsSpaNavigationEnabled,
    setGATrackingData,
    setNativeAppInfo,
    setFeatureSwitches,
    setSplitTestGroups,
    setVariables,
    setVersion
} = slice.actions;
export const reducer = slice.reducer;
