export const buildInitialGtmDataLayerFromInitData = (initData) => {
    // ***** ATTENTION !!! *****
    // If you change anything here you must
    // also change in buildInitialGtmDataLayerFromState
    // so that they match. Otherwise values
    // between Touchweb and NextWeb don't match.
    const initialDataLayer = {
        memberId: initData.memberId || 0,
        userLanguage: initData.languageCodeIso2,
        memberEmail: initData.isLoggedIn ? initData.memberEmail : '',
        memberHashedEmail: initData.isLoggedIn
            ? initData.memberEmailSha256
            : '',
        'criteo.hashedEmail': initData.isLoggedIn
            ? initData.memberEmailMd5
            : '',
        memberFirstName: initData.isLoggedIn ? initData.memberFirstName : '',
        memberLastName: initData.isLoggedIn ? initData.memberLastName : '',
        memberCountry: initData.isLoggedIn ? initData.memberCountry : '',
        loginState: initData.isLoggedIn ? 'logged in' : 'not logged in',
        isNotInIframe: window.self === window.top,
        'blueshift.event-api-key': initData.blueshiftEventApiKey,
        'blueshift.event-signature': initData.blueshiftEventSignature || '',
        'blueshift.event-verification-key': initData.memberId || 0,
        isSinglePageApp: initData.isSinglePageApp,
        isNativeAppContext: initData.isNativeAppContext,
        isQuantcastConsentEnabled:
            initData.featureSwitches['quantcast-consent'],
        isTikTokPixelEnabled: initData.featureSwitches['tiktok-pixel'],
        isDigitalAudienceTrackingEnabled:
            initData.featureSwitches['digital-audience-tracking'],
        quantcastSite: initData.featureSwitches['use-beta-consent-property']
            ? 'beta.tradera.com'
            : 'www.tradera.com'
    };
    // add split tests to datalayer
    if (initData.splitTestGroups) {
        for (const [key, value] of Object.entries(initData.splitTestGroups)) {
            initialDataLayer[`splittest_${key}`] = value;
        }
    }
    return initialDataLayer;
};

export const buildInitialGtmDataLayerFromState = (state) => {
    const { environment, language, member } = state;
    const { featureSwitches } = environment;
    const isQuantcastConsentEnabled = featureSwitches['quantcast-consent'];
    const isTikTokPixelEnabled = featureSwitches['tiktok-pixel'];
    const isDigitalAudienceTrackingEnabled =
        featureSwitches['digital-audience-tracking'];
    const quantcastSite = featureSwitches['use-beta-consent-property']
        ? 'beta.tradera.com'
        : 'www.tradera.com';
    const splitTestGroups = Object.entries(environment.splitTestGroups).reduce(
        (groups, [key, value]) => {
            return {
                ...groups,
                [`splittest_${key}`]: value
            };
        },
        {}
    );
    // ***** ATTENTION !!! *****
    // If you change anything here you must
    // also change in buildInitialGtmDataLayerFromInitData
    // so that they match. Otherwise values
    // between Touchweb and NextWeb don't match.
    return {
        memberId: member.memberId || 0,
        userLanguage: language.preferred.languageCodeIso2,
        memberEmail: member.memberEmail || '',
        memberHashedEmail: member.memberEmailSha256 || '',
        'criteo.hashedEmail': member.memberEmailMd5 || '',
        memberFirstName: member.memberFirstName || '',
        memberLastName: member.memberLastName || '',
        memberCountry: member.memberCountry || '',
        loginState: member.isLoggedIn ? 'logged in' : 'not logged in',
        isNotInIframe: window.self === window.top,
        'blueshift.event-api-key':
            environment.variables.PUBLIC_BLUESHIFT_EVENT_API_KEY,
        'blueshift.event-signature': member.blueshiftEventSignature || '',
        'blueshift.event-verification-key': member.memberId || 0,
        isSinglePageApp: true,
        isNativeAppContext: environment.isNativeAppContext,
        isQuantcastConsentEnabled,
        isDigitalAudienceTrackingEnabled,
        isTikTokPixelEnabled,
        quantcastSite,
        ...splitTestGroups
    };
};
