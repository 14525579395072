/**
 * @returns initData as JSON;
 * Default
 */
const initData = function () {
    if (typeof window === 'undefined') {
        return {};
    } else if (!window.initData) {
        const initDataEL = document.getElementById('init-data');
        if (initDataEL) {
            const data = initDataEL.getAttribute('data-init-data');
            const parsed = JSON.parse(data);
            return parsed;
        } else {
            return null;
        }
    } else {
        return window.initData;
    }
};

export const getInitData = initData;
export default new initData();
