/* eslint-disable better-mutation/no-mutation */

import i18n from 'i18next';
import { logger } from 'packages/logger';
// eslint-disable-next-line no-restricted-imports
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { toLocalizedUrl } from 'tradera/utils/url';
import { formatNumberWithSeparators } from 'tradera/utils/format';
import { missingKeyLoggingConfig } from 'tradera/lang/shared-config';
import {
    NS_TOUCHWEB,
    NS_ATTRIBUTES,
    DEFAULT_LANGUAGE
} from 'tradera/lang/constants.mjs';
import 'dayjs/locale/sv';
import 'dayjs/locale/da';
import 'dayjs/locale/de';
import { isServer } from 'tradera/utils/nextjs';

let i18nInitialized;

/**
 * Initialize function for i18next
 * Must be run before doing any translations
 * @param {Array} [translations]
 * @param {Object} [options]
 * @returns {Promise<Function>} - a promise to the i18next t function
 */
export const bootLanguages = (
    translations = window.touchwebTranslations,
    attributeTranslations = window.attributeTranslations
) => {
    if (isServer) {
        // Do not remove this without also removing the disable of eslint better-mutation/no-mutation at top of file.
        throw new Error(
            'This implementation is not compatible with SSR as global properties are shared between server instances'
        );
    }

    const translationResources = Object.entries(translations).map(
        ([key, value]) => ({
            key,
            value,
            namespace: 'touchWeb'
        })
    );
    const attributeResources = Object.entries(attributeTranslations).map(
        ([key, value]) => ({ key, value, namespace: 'attributes' })
    );

    const resources = translationResources
        .concat(attributeResources)
        .reduce((prev, { key, value, namespace }) => {
            const s = prev[key] || {};
            s[namespace] = value;
            return {
                ...prev,
                [key]: s
            };
        }, {});

    i18nInitialized = i18n
        .use(initReactI18next)
        .use(LanguageDetector)
        .init(initOptions(resources), (err) => {
            if (err) {
                logger('error when loading translations', err);
            }
        });

    return i18nInitialized;
};

export const initOptions = (resources) => ({
    detection: {
        order: ['htmlTag'],
        htmlTag: document.html
    },
    defaultNS: [NS_TOUCHWEB],
    ns: [NS_ATTRIBUTES],
    fallbackLng: DEFAULT_LANGUAGE,
    resources,
    debug: false,
    keySeparator: false,
    nsSeparator: ':::',
    interpolation: {
        skipOnVariables: true,
        escapeValue: false,
        format: (value, format) => {
            if (format === 'formatNumber') {
                return formatNumberWithSeparators(value);
            }

            return value;
        }
    },
    react: {
        useSuspense: false // loading from file currently breaks if this is true
    },
    ...missingKeyLoggingConfig
});

/**
 * This function helps you make sure that i18next init has completed before continuing
 */
export const whenInitialized = () => {
    if (!i18nInitialized) {
        throw new Error(
            "Can't call boot-languages whenInitialized before init!"
        );
    }
    return i18nInitialized.then((t) => ({
        t,
        toLocalizedUrl: (url) => toLocalizedUrl(url, i18n.language)
    }));
};

/**
 * Only required for Storybook, do not use.
 */
export const changeLanguage = (language) => i18n.changeLanguage(language);

export const getCurrentLanguage = () => i18n.language;
