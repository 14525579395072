/* eslint-disable better-mutation/no-mutation */

/**
 *
 * # track events:
 * import { GtmService } from 'tradera/services/google-tagmanager/google-tagmanager-service';
 * GtmService.trackGtmEvent("zorro", { marvel: false, black: true })
 *
 *
 *
 */

import * as Sentry from '@sentry/react';
import initData from 'tradera/legacy/static/packages/init-data';
import { isServer, isNextJs, isTouchwebInNextweb } from 'tradera/utils/nextjs';
import { buildInitialGtmDataLayerFromInitData } from 'tradera/utils/google-tagmanager';
import { queueBackgroundTask } from 'tradera/utils/scheduler';
import { googleTagManagerSnippet } from 'tradera/services/google-tagmanager/google-tagmanager-snippet';
import type { SOURCE_SCREEN } from 'tradera/services/google-tagmanager/constants';
import type { ItemCardSearchResultOrigin } from 'tradera/state/services/types/webapi-discover-generated';

declare global {
    interface Window {
        dataLayer: unknown[];
        legacyDataLayer: unknown[];
        logGaWebViewEvent?: (name: string, params: unknown) => void;
        setGaWebViewUserProperty?: (name: string, value: string) => void;
        AnalyticsWebInterface?: {
            logEvent: (name: string, params: string) => void;
            setUserProperty: (name: string, value: string) => void;
        };
        webkit?: {
            messageHandlers?: {
                firebase: {
                    postMessage: (message: unknown) => void;
                };
            };
        };
    }
}

/**
 * SPA page view tracking becomes enabled after first
 * page view tracking which happens after the first
 * page is loaded from server.
 * This prevents reset of data layer after a SPA route has
 * changed (happens late after full page load) and prevents
 * duplicated trackPageView events.
 */
let spaPageViewTrackingEnabled = false;

class GoogleTagManagerService {
    isScriptLoaded: boolean;
    constructor() {
        this.isScriptLoaded = false;

        if (!isNextJs) this._setWebViewHelperFunctionsToWindow();
    }

    loadGtmScript() {
        if (!isNextJs || isTouchwebInNextweb) {
            this._newPageFromServer();
        }
        const accountId =
            (typeof process === 'object' &&
                process?.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ACCOUNT_ID) ||
            'GTM-5TMB2D';
        googleTagManagerSnippet(accountId);
        this.isScriptLoaded = true;
    }

    push(payload: object) {
        if (isServer) {
            return;
        }

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(payload);
    }

    pushArguments(...args: unknown[]) {
        if (isServer) {
            return;
        }
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(...args);
    }

    _getDatalayerObject(): Record<string, unknown> {
        let output = {};
        if (window.dataLayer) {
            for (const entry of window.dataLayer) {
                output = { ...output, ...(entry as Record<string, unknown>) };
            }
        }
        return output;
    }

    /**
     * destroy datalayer from previous loading. this is really only useful in spa pages, it's backwards compatible so it doesnt do any harm on old pages
     */
    _reset() {
        if (!window.dataLayer) {
            return;
        }

        const data = this._getDatalayerObject();
        for (const key of Object.keys(data)) {
            data[key] = undefined;
        }
        window.dataLayer.push({ ...data, event: 'reset' });
    }

    _hasPreviouslyTrackedPage = () =>
        !!window.dataLayer?.find(
            (item) => (item as { event: string }).event === 'trackPageview'
        );

    newPage(pageType: string, initialDataLayer: object) {
        if (this._hasPreviouslyTrackedPage()) {
            this._reset();
        }
        this._pushInitialDataLayer(initialDataLayer);
        // It is important that the "Container loaded" triggered by dataLayer.push({event: "gtm.js", ...})
        // happens after "initialDataLayer" otherwise variables for the GDPR banner may have the wrong
        // values in Tagmanager. For that reason the GTM script is loaded here after initialDatalayer is set.
        if (!this.isScriptLoaded) {
            this.loadGtmScript();
        }
        this.push({ event: 'pageType', 'page.pageType': pageType });
    }

    /**
     * Same as newPage but with logic for case in Touchweb where initial
     * datalayer was set on server and already tracked on landing page.
     * This function does nothing until after first call to trackPageView
     * (e.g. after a SPA-navigation away from the landing page).
     */
    newSpaPage(pageType: string, initialDataLayer: Record<string, unknown>) {
        if (!spaPageViewTrackingEnabled) {
            return;
        }
        this.newPage(pageType, initialDataLayer);
    }

    _newPageFromServer() {
        const initialDataLayer = buildInitialGtmDataLayerFromInitData(initData);
        this._pushInitialDataLayer(initialDataLayer);
        // ------------------------------------------------
        // backwards compatibility
        // get old datalayer info and push it in
        if (window.legacyDataLayer) {
            for (const entry of window.legacyDataLayer) {
                window.dataLayer.push({
                    event: 'tradera/legacyDataLayer',
                    ...(entry as unknown[])
                });
            }
        }
    }

    _pushInitialDataLayer(initialDataLayer: object) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            ...initialDataLayer,
            event: 'initialDataLayer'
        });
    }

    /**
     * Tracks a google analytics event asynchronously
     * Good for SEO, users and Web Vitals Interaction to Next Paint.
     * @param {string} category ga category
     * @param {string} action ga action what happens, ie: "Filter box - open/close"
     * @param {string} [label] ga label what the value of the action was, ie "close"
     * @param {integer} [value] ga interger value of the action
     * @param {boolean} [nonInteractive] if the action does not stems from a user interaction
     */
    trackAction(
        category: string,
        action: string,
        label?: string,
        value?: number,
        nonInteractive?: boolean
    ) {
        queueBackgroundTask(() =>
            this.trackActionSynchronously(
                category,
                action,
                label,
                value || 0,
                nonInteractive ?? false
            )
        );
    }

    /**
     * Tracks a google analytics event synchronously
     * @deprecated In 99% of cases, use `trackAction` instead. Using this can cause high Web Vitals INP.
     * @param {string} category ga category
     * @param {string} action ga action what happens, ie: "Filter box - open/close"
     * @param {string} [label] ga label what the value of the action was, ie "close"
     * @param {integer} [value] ga interger value of the action
     * @param {boolean} [nonInteractive] if the action does not stems from a user interaction
     */
    trackActionSynchronously(
        category: string,
        action: string,
        label?: string,
        value?: number,
        nonInteractive?: boolean
    ) {
        this.push({
            event: 'trackEvent',
            eventCategory: category || '',
            eventAction: action || '',
            eventLabel: label || '',
            eventValue: value || '0',
            eventNonInteractive: nonInteractive || false
        });

        Sentry.addBreadcrumb({
            type: 'default',
            level: 'info',
            category: nonInteractive ? 'tracking' : 'ui-action',
            message: 'Analytics Event',
            data: {
                category,
                action,
                label,
                value: value ?? 0
            }
        });
    }

    /**
     * google tag manager event. Note: if you want to send google analytics events use trackAction instead
     * @param {string} eventName
     * @param {object} [data] data
     */
    trackGtmEvent(eventName: string, data: object = {}) {
        this.push({
            event: eventName,
            ...data
        });
    }

    /**
     * Track a pageview. Use this script in SPA's to make sure that analytics gets pageview information.
     * This creates a virtual page view since google analytics by default doesn't track changes to url via
     * push state.
     */
    trackPageView() {
        this.trackGtmEvent('trackPageview');
        spaPageViewTrackingEnabled = true;
    }

    trackLinkClickAndCallback(
        category: string,
        action: string,
        label: string,
        callback: () => void,
        value: unknown = 0
    ) {
        this.push({
            event: 'trackEvent',
            eventCategory: category || '',
            eventAction: action || '',
            eventLabel: label || '',
            eventValue: value || '0',
            eventNonInteractive: false,
            eventCallback: callback // part of google tag manager api to execute code after all tags in datalayer have been executed
        });
    }

    trackLinkClickAndGotoUrl(
        category: string,
        action: string,
        label: string,
        url?: string,
        value = 0
    ) {
        const callback = () => {
            if (!url) return;
            location.href = url;
        };
        this.trackLinkClickAndCallback(
            category,
            action,
            label,
            callback,
            value
        );
    }

    /**
     * Set up helper functions which purpose is to post data to
     * either Android or iOS
     * These are accessed by Google Tag Manager
     *
     * https://firebase.google.com/docs/analytics/webview?platform=ios
     */
    _setWebViewHelperFunctionsToWindow() {
        window.logGaWebViewEvent = (name: string, params: unknown) => {
            if (!name) {
                return;
            }

            if (window.AnalyticsWebInterface) {
                // Call Android interface
                window.AnalyticsWebInterface.logEvent(
                    name,
                    JSON.stringify(params)
                );
            } else if (
                window.webkit &&
                window.webkit.messageHandlers &&
                window.webkit.messageHandlers.firebase
            ) {
                // Call iOS interface
                const message = {
                    command: 'logEvent',
                    name: name,
                    parameters: params
                };
                window.webkit.messageHandlers.firebase.postMessage(message);
            }
        };

        window.setGaWebViewUserProperty = (name, value) => {
            if (!name || !value) {
                return;
            }

            if (window.AnalyticsWebInterface) {
                // Call Android interface
                window.AnalyticsWebInterface.setUserProperty(name, value);
            } else if (
                window.webkit &&
                window.webkit.messageHandlers &&
                window.webkit.messageHandlers.firebase
            ) {
                // Call iOS interface
                const message = {
                    command: 'setUserProperty',
                    name: name,
                    value: value
                };
                window.webkit.messageHandlers.firebase.postMessage(message);
            }
        };
    }
    /**
     * Track event to GA4 via GTM.
     *
     * `timestamp` will always be attached.
     *
     * @example
     * GtmService.trackGA4('saved_search_deleted', {
     *     saved_search_id: id
     * })
     */
    trackGA4(
        eventName: string,
        eventParams: EventParams = {},
        userData: UserData = {}
    ) {
        const payload = {
            event: 'track_ga4_event',
            eventName,
            eventParams: {
                timestamp: Date.now(),
                ...eventParams
            },
            userData
        };
        this.push(payload);
    }
}

export const GtmService = new GoogleTagManagerService();

export enum FlowId {
    Unknown = 0,
    SYI = 1,
    VIP = 2
}

export type GA4Item = {
    item_id: number;
};

// All new eventParams must be added to GTM
// Mapped in "GA4 - Track events" and separate variable definitions
export type EventParams = {
    flow_id?: FlowId;
    boolean_value?: 1 | 0;
    item_id?: number;
    timestamp?: number;
    source_section?: string;
    items?: GA4Item[];
    source_screen?: SOURCE_SCREEN;

    // Wishlist specific params
    wishlist_type?: string;

    // Purchase offer specific params
    item_status?: 'active' | 'ended';

    // SYI specific params
    draft_id?: string;
    form_name?: 'syi_draft' | 'syi';
    syi_session_id?: string;
    attribute_name?: string;
    ai_title?: 1 | 0;
    ai_description?: 1 | 0;
    ai_images?: number;
    ai_processing_time?: number;
    suggested_price_lower?: number;
    suggested_price_upper?: number;

    // Item card specific params
    item_placement?: number;
    pagination?: number;

    // pagination specific params
    direction?: string;

    // search specific params
    search_type?: string;
    search_term?: string;
    search_category?: number;
    sort_type?: string;
    saved_search_type?: string;
    saved_search_id?: number;
    search_model?: ItemCardSearchResultOrigin;
    exact_search?: number;

    // scroll specific params
    scroll_percentage?: number;
    scroll_percentage_max?: number;

    // registration specific params
    external_company?: string;
};

type UserData = {
    member_id?: number;
};
