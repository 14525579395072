export const safeDecodeURIComponent = (uriComponent: string) => {
    if (!uriComponent) {
        return '';
    }
    try {
        return decodeURIComponent(uriComponent);
    } catch {
        return '';
    }
};
