import { dangerouslyParseJwtWithoutVerifyingSignature } from 'tradera/backend/utils/jwt';

export const getMemberIdFromAuthHeader = (req: {
    headers: NodeJS.Dict<string | string[]>;
}): number | undefined =>
    dangerouslyParseJwtWithoutVerifyingSignature(
        getFirstHeaderValueOrEmpty(req, 'authorization').substring(
            'Bearer '.length
        )
    )?.mbr;

export const getFirstHeaderValueOrEmpty = (
    req: {
        headers: NodeJS.Dict<string | string[]>;
    },
    headerName: string
) => {
    const header = req.headers[headerName] || '';
    const headerValue = Array.isArray(header) ? header.shift() : header;
    return headerValue || '';
};
