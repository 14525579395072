import * as Sentry from '@sentry/react';
import type { CaptureContext } from '@sentry/types';
import { AxiosError } from 'axios';
import { isClientNetworkError } from 'tradera/utils/api';

type SomeError = Error | string | object | unknown;

/**
 * @param {object} error - takes an exception and logs to Sentry or the console in development
 * @param {object} [scope] - Sentry CaptureContext created with new Sentry.Scope() or plain object
 * @desc logger is designed for logging errors. It will accept an argument of any type and create a stacktrace containing a reference to the callee.
 * @TODO Migrate this functionality to a logging module that handles different levels of logs
 */
export const logger = (error: SomeError, scope?: CaptureContext) => {
    if (error instanceof AxiosError && isClientNetworkError(error)) {
        // Ignore client network API errors like aborts, timeouts etc.
        return;
    }

    let sentryException;
    if (error instanceof Error) {
        sentryException = error;
    } else if (typeof error === 'string') {
        sentryException = new Error(error);
    } else {
        sentryException = new Error(JSON.stringify(error));
    }
    if (scope) {
        Sentry.captureException(sentryException, scope);
    } else {
        Sentry.captureException(sentryException);
    }
};
