export const LIST_VIEW_TYPES = {
    BASIC: 'Basic',
    NORMAL: 'Normal'
} as const;

export const PAGE_LIST_TYPES = {
    BUYER_ACTIVE_ITEMS: 'BUYER_ACTIVE_ITEMS',
    BUYER_PURCHASES: 'BUYER_PURCHASES',
    BUYER_ITEMS_LOST: 'BUYER_ITEMS_LOST',
    SELLER_ACTIVE: 'SELLER_ACTIVE',
    SELLER_SOLD: 'SELLER_SOLD',
    SELLER_NOT_SOLD: 'SELLER_NOT_SOLD'
} as const;

export const PAGE_BULK_ACTIONS = {
    ACTIVE_CANCEL: 'ActiveItems_Cancel',
    UNSOLD_RESTART: 'UnsoldItems_Restart',
    BUYER_SHOW_ACTIVE: 'ShowActive',
    BUYER_HIDE_ACTIVE: 'HideActive'
} as const;
