/**
 * A queue with a that handled max age (ttl) for messages.
 * @returns
 */
export const createQueue = () => {
    const items = [];
    const dayInMinutes = 60 * 24;
    const add = (item, ttlInMinutes = dayInMinutes) => {
        const timeStamp = Date.now();
        items.push({ item, ttlInMinutes, timeStamp });
    };
    const isAlive = ({ ttlInMinutes, timeStamp }) => {
        return timeStamp + ttlInMinutes * 60 * 1000 >= Date.now();
    };
    let inProgress = null;
    return {
        add,
        next: () => {
            let itemData = items.shift();
            while (itemData && !isAlive(itemData)) {
                itemData = items.shift();
            }
            // eslint-disable-next-line better-mutation/no-mutation
            inProgress = itemData?.item || null;
            return inProgress;
        },
        notifyDone: () => {
            // eslint-disable-next-line better-mutation/no-mutation
            inProgress = null;
        },
        isInProgress: () => inProgress !== null,
        getLength: () => items.length
    };
};
