import { URL_LANGUAGES } from 'tradera/lang/constants.mjs';

// Add any new NextWeb routes to this array to enable SPA navigation.
// This is the part of the path after the language prefix until the end.
export const NEXTWEB_SPA_REGEX_PATHS = [
    '/?$',
    '/brand/',
    '/brands$',
    '/campaign/',
    '/categories$',
    '/category/([0-9]+)',
    '/cause/',
    '/causes$',
    '/causes/items$',
    '/charity/',
    '/fashion$',
    '/giftcards$',
    '/how-to-buy$',
    '/how-to-sell$',
    '/item/[0-9]+(?:/[0-9]+)?',
    '/ma/endless-recommendation',
    '/mode$',
    '/my/bidding$',
    '/my/invoices/confirmation/[^/]+$',
    '/my/[Ii]nvoices/[Pp]ayment/[^/]+$',
    '/my/listings$',
    '/my/order/item/[^/]+$',
    '/my/order/[^/]+$',
    '/my/overview$',
    '/my/overview-seller$',
    '/my/overview-buyer$',
    '/my/overview2$',
    '/my/picklist$',
    '/my/purchases$',
    '/my/refundcheckout$',
    '/my/shipping$',
    '/my/shipping/checkout$',
    '/my/shipping/complaint/',
    '/my/shipping/confirmation/[0-9a-zA-Z]{20,}',
    '/my/sold$',
    '/my/sold/picklist/print2$',
    '/my/sold2$',
    '/my/wish-list$',
    '/pokemon$',
    '/profile/feedback/',
    '/profile/information/',
    '/profile/items/',
    '/resolution/feedback-removal$',
    '/search/detailed$',
    '/search/detailed/member$',
    '/shop/',
    '/shopping/cart$',
    '/shopping/ordercheckout/confirmation/[^/]+$',
    '/shopping/ordercheckout/[^/]+$',
    '/top-lists$',
    '/top-lists/'
];

export const NEXTWEB_SPA_REGEX_TOGGLES = {
    '/dispute/klarna$': 'dispute-v2-add',
    '/dispute/paypal$': 'dispute-v2-add',
    '/dispute/no-bp$': 'dispute-v2-add',
    '/dispute/new/': 'dispute-v2-add',
    '/login': 'next-web-login'
};

const PATH_PREFIX = `(/(${URL_LANGUAGES.join('|')}))?`;

const createPathRegex = (pathRegex: string) =>
    new RegExp(`^${PATH_PREFIX}${pathRegex}`);

const getToggledOnPathRegex = (toggles: Record<string, boolean>) =>
    Object.entries(NEXTWEB_SPA_REGEX_TOGGLES)
        .filter(([, toggleName]) => toggles[toggleName])
        .map(([path]) => path);

export const isLinkToNextWeb = (
    absoluteUrl: URL,
    location: URL,
    toggles: Record<string, boolean>
) => {
    const pathname = absoluteUrl?.pathname;

    if (absoluteUrl.searchParams.get('next') === '1') {
        return true;
    }

    if (
        createPathRegex('/search$').test(pathname) &&
        !!absoluteUrl.searchParams.get('q')
    ) {
        return true;
    }

    const toggledOnRegexPaths = getToggledOnPathRegex(toggles);
    const nextwebRegexFullPaths =
        NEXTWEB_SPA_REGEX_PATHS.concat(toggledOnRegexPaths).map(
            createPathRegex
        );

    if (nextwebRegexFullPaths.some((regex) => regex.test(pathname))) {
        return true;
    }

    return false;
};
