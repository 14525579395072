export function isNullOrUndefined(value: unknown): value is null | undefined {
    // For undeclared variables, typeof foo will return the string literal
    // "undefined", whereas the identity check foo === undefined would
    // trigger the error "foo is not defined".
    return value === null || typeof value === 'undefined';
}

export function isNotNullOrUndefined<TValue>(
    value: TValue | null | undefined
): value is TValue {
    return value !== null && typeof value !== 'undefined';
}

export function isUndefinedOrEmpty(value: string | undefined) {
    return value === undefined || (value as string).trim() === '';
}
