import omitBy from 'lodash/omitBy';
import mapObject from 'lodash/mapValues';

export const nullifyUndefinedProperties = <T extends Record<string, unknown>>(
    obj: T
): { [K in keyof T]: T[K] extends undefined ? null : T[K] } =>
    mapObject(obj, (value) =>
        typeof value === 'undefined' ? null : value
    ) as unknown as { [K in keyof T]: T[K] extends undefined ? null : T[K] };

type NonNullableProperties<T> = {
    [K in keyof T]: Exclude<T[K], null | undefined>;
};

export function removePropertiesWithoutValue<T extends Record<string, unknown>>(
    obj: T,
    removeEmptyString?: boolean
): NonNullableProperties<T> {
    return omitBy(
        obj,
        (value) =>
            value === null ||
            value === undefined ||
            (removeEmptyString && value === '')
    ) as NonNullableProperties<T>;
}
