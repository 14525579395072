// Note that this does not validate the signature of the token!
export const dangerouslyParseJwtWithoutVerifyingSignature = (
    token?: string
) => {
    if (!token) {
        return null;
    }
    const base64Payload = token.split('.')[1];
    if (!base64Payload) {
        return null;
    }
    try {
        const payload = Buffer.from(base64Payload, 'base64');
        return JSON.parse(payload.toString());
    } catch (error) {
        return null;
    }
};
