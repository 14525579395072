import { useMemo } from 'react';
import { useAppSelector } from 'tradera/state/hooks';
import { isServer } from 'tradera/utils/nextjs';

export const useLocation = (fallback = '') => {
    const request = useAppSelector((state) => state.request);
    const location = isServer
        ? // During static site generation isServer will be true, but we do not
          // have access to a request object. In those cases the consumer can pass in a fallback.
          (request.location ?? fallback)
        : window.location.href;
    const url = useMemo(() => new URL(location), [location]);
    return url;
};
