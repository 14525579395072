import type { MutableRefObject } from 'react';
import { createContext, createRef, useCallback, useContext } from 'react';
import { NS_ATTRIBUTES, NS_TOUCHWEB } from 'tradera/lang/constants.mjs';
import { isServer } from 'tradera/utils/nextjs';

type TranslationKeyValue = Record<string, string>;
type NamespaceTranslations = Record<string, TranslationKeyValue> | null;

const initialState: NamespaceTranslations = {
    [NS_ATTRIBUTES]: {},
    [NS_TOUCHWEB]: {}
};

export const TranslationContext = createContext<
    MutableRefObject<NamespaceTranslations>
>({ current: initialState });

export const TranslationProvider = ({
    children
}: {
    children: React.ReactNode;
}) => {
    const translationsRef: MutableRefObject<NamespaceTranslations> =
        createRef<NamespaceTranslations>();
    translationsRef.current = initialState;

    return (
        <TranslationContext.Provider value={translationsRef}>
            {children}
        </TranslationContext.Provider>
    );
};

export const useTranslations = () => {
    const translationsRef = useContext(TranslationContext);

    return translationsRef;
};

export type AddTranslation = (
    namespace: string,
    pluralizedKey: string,
    translation: string
) => void;

export const useAddTranslation = (): AddTranslation => {
    const translationsRef = useContext(TranslationContext);

    return useCallback(
        (namespace, pluralizedKey, translation) => {
            if (!isServer) {
                return;
            }

            if (!translationsRef?.current) {
                return;
            }

            if (!translationsRef.current[namespace]) {
                translationsRef.current[namespace] = {};
            }
            translationsRef.current[namespace][pluralizedKey] = translation;
        },
        [translationsRef]
    );
};
