/***
 *
 * Keep track of users' GDPR settings.
 *
 */
import dayjs from 'dayjs';
import cookie from 'cookie';

import {
    GDPR_CONSENT_COOKIE,
    COOKIE_CATEGORIES
} from 'tradera/constants/cookies';
import { getCookieFromBrowser, setCookieToBrowser } from './cookie-helpers';

const createCookie = (name, value, expires) => {
    const options = {
        expires,
        httpOnly: false,
        path: '/'
    };
    return cookie.serialize(name, String(value), options);
};

export class GdprSettings {
    constructor(getCookie, setCookie) {
        if (!getCookie || !setCookie) {
            throw new Error('You must provide getCookie and setCookie');
        }
        this.getCookie = getCookie;
        this.setCookie = setCookie;
    }
    /**
     *
     * @param {int} cookieCategory use the constant COOKIE_CATEGORIES to get different values.
     */
    isCategoryEnabled(cookieCategory) {
        if (cookieCategory === COOKIE_CATEGORIES.Essential) {
            return true;
        }

        const encodedConsentCookie = this.getCookie(GDPR_CONSENT_COOKIE);
        const consentCookie = decodeURI(encodedConsentCookie);

        if (!consentCookie || consentCookie.length === 0) {
            return true;
        }

        const categoryPattern = new RegExp(`,${cookieCategory}:1`);
        return categoryPattern.test(consentCookie);
    }

    isPerformanceEnabled() {
        return this.isCategoryEnabled(COOKIE_CATEGORIES.Performance);
    }

    isFunctionalEnabled() {
        return this.isCategoryEnabled(COOKIE_CATEGORIES.Functional);
    }

    isMarketingEnabled() {
        return this.isCategoryEnabled(COOKIE_CATEGORIES.Marketing);
    }

    set(performance, functional, marketing) {
        const cookieValue = [
            `${COOKIE_CATEGORIES.Essential}:1`,
            `${COOKIE_CATEGORIES.Performance}:${performance ? '1' : '0'}`,
            `${COOKIE_CATEGORIES.Functional}:${functional ? '1' : '0'}`,
            `${COOKIE_CATEGORIES.Marketing}:${marketing ? '1' : '0'}`
        ];
        const cookieString = createCookie(
            GDPR_CONSENT_COOKIE,
            cookieValue.join(','),
            dayjs().add(1, 'years').toDate()
        );
        this.setCookie(cookieString);
    }
}

export default new GdprSettings(getCookieFromBrowser, setCookieToBrowser);
