/* eslint-disable better-mutation/no-mutation */

export const googleTagManagerSnippet = (accountId) =>
    (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({
            'gtm.start': new Date().getTime(),
            event: 'gtm.js'
        });
        const f = d.getElementsByTagName(s)[0];
        const j = d.createElement(s);
        j.async = true;
        j.src = '//www.googletagmanager.com/gtm.js?id=' + i;
        f.parentNode?.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', accountId);
