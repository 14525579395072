import { URL_LANGUAGES } from 'tradera/lang/constants.mjs';

// Add any new Touchweb in NextWeb hybrid routes to this array to enable SPA navigation.
// This is the part of the path after the language prefix until the end.
export const TW_IN_NW_HYBRID_SPA_REGEX_PATHS = [
    '/verify/kyc-questionnaire$',
    '/verify/collect-vat-number$',
    '/verify/collect-tin$',
    '/report-item',
    '/favourite-sellers$'
];

export const TW_IN_NW_HYBRID_SPA_REGEX_TOGGLES = {
    '/frakt': 'next-web-shipping',
    '/valuation$': 'next-web-valuation',
    '/messaging$': 'next-web-messaging',
    '/selling/templates$': 'next-web-syi-templates',
    '/selling/template': 'next-web-syi-templates',
    '/selling$': 'next-web-syi-drafts',
    '/selling/campaign/': 'next-web-syi-drafts',
    '/selling/draft/[0-9a-fA-F]+$': 'next-web-syi-drafts',
    '/selling/summary/[0-9a-fA-F]+$': 'next-web-syi-drafts',
    '/selling/draft/[0-9a-fA-F]+/edit/|a-zA-Z,]+$': 'next-web-syi-drafts',
    '/selling/new$': 'next-web-syi-drafts',
    '/selling/published/[0-9]+$': 'next-web-syi-drafts',
    '/selling/extra/[0-9a-fA-F]+$': 'next-web-syi-drafts',
    '/selling/features/[0-9]+$': 'next-web-syi-drafts',
    '/my/accounting$': 'next-web-mytradera-hybrid',
    '/my/invoices$': 'next-web-mytradera-hybrid',
    '/my/leave-feedback/buyer$': 'next-web-mytradera-hybrid',
    '/my/leave-feedback/seller$': 'next-web-mytradera-hybrid',
    '/my/payouts$': 'next-web-mytradera-hybrid',
    '/my/saved-searches$': 'next-web-mytradera-hybrid',
    '/my/seller-list$': 'next-web-mytradera-hybrid',
    '/my/settings$': 'next-web-mytradera-hybrid',
    '/my/settings/account$': 'next-web-mytradera-hybrid',
    '/my/settings/email$': 'next-web-mytradera-hybrid',
    '/my/settings/security$': 'next-web-mytradera-hybrid',
    '/my/settings/localization$': 'next-web-mytradera-hybrid',
    '/my/settings/selling$': 'next-web-mytradera-hybrid',
    '/my/settings/notifications$': 'next-web-mytradera-hybrid',
    '/my/settings/payouts$': 'next-web-mytradera-hybrid'
};

const PATH_PREFIX = `(/(${URL_LANGUAGES.join('|')}))?`;

const createPathRegex = (pathRegex: string) =>
    new RegExp(`^${PATH_PREFIX}${pathRegex}`);

const getToggledOnPathRegex = (toggles: Record<string, boolean>) =>
    Object.entries(TW_IN_NW_HYBRID_SPA_REGEX_TOGGLES)
        .filter(([, toggleName]) => toggles[toggleName])
        .map(([path]) => path);

export const isLinkToTouchwebInNextWeb = (
    absoluteUrl: URL,
    toggles: Record<string, boolean>
) => {
    const pathname = absoluteUrl?.pathname;

    const toggledOnRegexPaths = getToggledOnPathRegex(toggles);
    const nextwebRegexFullPaths =
        TW_IN_NW_HYBRID_SPA_REGEX_PATHS.concat(toggledOnRegexPaths).map(
            createPathRegex
        );
    if (nextwebRegexFullPaths.some((regex) => regex.test(pathname))) {
        return true;
    }

    return false;
};
