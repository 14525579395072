/**
 * This is a workaround to remove the following predefined GTM tag iframes on SPA navigation:
 * - Google Ads Remarketing
 * - Google Ads Conversion Tracking
 * - Floodlight Counter
 * - Floodlight Sales
 * - Criteo
 * Iframe are removed to prevent these iframes from duplicating the browser
 * back/forward-history entries, which seems to be a bug on Google's side:
 * https://support.google.com/tagmanager/thread/237899073/?hl=en
 *
 * This issue is likely originating from the introduction of the
 * Privacy Sandbox https://privacysandbox.com/
 */

const SANDBOX_IFRAME_SRC = [
    'https://td.doubleclick.net/',
    'https://fledge.eu.criteo.com/'
];

const isSandboxOrigin = (node: HTMLIFrameElement) =>
    typeof node.src === 'string' &&
    SANDBOX_IFRAME_SRC.some((sandboxOrigins) =>
        node.src.startsWith(sandboxOrigins)
    );

const isPrivacySandbox = (node: HTMLIFrameElement) =>
    typeof node.allow === 'string' &&
    node.allow.includes('join-ad-interest-group');

const isGoogleAdsIframe = (node: Node): boolean =>
    node instanceof HTMLIFrameElement &&
    isSandboxOrigin(node) &&
    isPrivacySandbox(node);

export const removePrivacySandboxIframes = () =>
    Array.from(
        document.body.querySelectorAll<HTMLIFrameElement>(
            'iframe[allow="join-ad-interest-group"]'
        )
    )
        .filter(isGoogleAdsIframe)
        .forEach((iframe) => iframe.remove());
